"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_WELCOME_VIDEO = exports.APP_CONTACTS = exports.APP_THEME = exports.APP_DOMAIN = exports.APP_NAME = void 0;
exports.APP_NAME = `Aba Milhas`;
exports.APP_DOMAIN = 'https://www.abamilhas.com.br';
exports.APP_THEME = {
    palette: {
        background: {
            default: '#ebebeb'
        },
        primary: {
            main: '#003847'
        }
    }
};
exports.APP_CONTACTS = {
    telegram: 'NextPontoseMilhas',
    whatsapp: '5511918569055',
    feedback_form_link: ''
};
exports.APP_WELCOME_VIDEO = 'https://www.youtube.com/watch?v=raKpjvJQ7LI&ab_channel=ErikGaldino';
